import React from 'react';
import './Partners.scss'
import Birla from '../../images/Aditya Birla.png'
import Chhaswala from '../../images/Chaaswala.png'
// import Clear from '../../images/Clear.png'
import Continental from '../../images/Continental.png'
import Dash from '../../images/Dash.png'
import Flourish from '../../images/Flourish.png'
import FMS from '../../images/FMS.png'
import Hindustan from '../../images/Hindustan.png'
import JayWater from '../../images/Jay Water.png'
import NMIMS from '../../images/NMIMS.png'
import Oberoi from '../../images/Oberoi.png'
import OneCentre from '../../images/one centre.png'
import pistonClub from '../../images/piston club.png'
import nutron from '../../images/nutron.png'
import swaraj from '../../images/swaraj.png'
import washkr from '../../images/washkr.png'
import wajooba from '../../images/wajooba.png'


function Partners() {
  return (
      <div className="partners">
          <div className="custom-container">
              <div className="partners-caption">
                  <p>Relations built on trust</p>
              </div>

              <div className="partners-title">
                    <h1>Our Partners</h1>
              </div>

              <div className="partners-main">
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Birla} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Chhaswala} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={pistonClub} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Continental} alt="" />
                            </div>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Dash} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Flourish} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={FMS} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Hindustan} alt="" />
                            </div>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={JayWater} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={NMIMS} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={Oberoi} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={OneCentre} alt="" />
                            </div>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={nutron} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={wajooba} alt="" />
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="partner-img">
                                <img src={swaraj} alt="" />
                            </div>
                        </div>

                        
                        
                    </div>
              </div>
          </div>
      </div>
  )
}

export default Partners;
