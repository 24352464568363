import random1 from '../images/random1.jpg'

import logo1 from '../images/Logos/8.png'
import logo2 from '../images/Logos/1.png'
import logo3 from '../images/Logos/2.png'
import logo4 from '../images/Logos/11.png'
import logo5 from '../images/Logos/6.png'
import logo6 from '../images/Logos/14.png'
import logo7 from '../images/Logos/15.png'
import logo8 from '../images/Logos/16.png'

import medium from '../images/small-ent.jpg'
import large from '../images/large-ent.jpg'
import education from '../images/education.jpg'
import government from '../images/government.jpg'
import health from '../images/health-care.jpg'

// Small Enterprise
export const smallEnterprise = {
    type: "Medium Enterprise",
    captionPara: "Limited resources, underfunding, workforce shortages — How can we help the education network face these challenges and go digital?",
    
    paraContentTitle: "To remain competitive, SMEs seek to do more with less, while gaining agility and productivity. Information technology offers this edge.",
    paraContentText1: "A transformation adapted to your needs India has more than 4,300 SMEs and each of them is different. IT solutions must be tailor-made to meet the equally unique needs of SMEs.",
    paraContentText2: "We know how your technological realities don’t always meet your business realities.",
    paraContentText3: "And that’s why in addition to having an IT infrastructure, we help you work and collaborate smarter by increasing the mobility of your employees and reducing the risk of errors and breakdowns. With tailor-made solutions, we make your infrastructures agile and scalable. By installing easy collaboration processes, we help you take your communications to the next level. The protection and control of your data is always a priority for us.",
    paraContentText4: "Whether you need a dedicated team or a Rapigrow expert in your business, with our infrastructure management services, you will gain productivity, be able to control your costs and, above all, be able to focus on what is really important: your SME. ",
    paraContentText5: "",
    paraContentText6: "",

    quote: "My team mastered the objectives, but not the means put in place to achieve them. Rapigrow has supported us both strategically and practically in our digital transformation.",

    randomLink: random1,

    Benefit1: "Increase in productivity",
    Benefit2: "Cost control",
    Benefit3: "Performance gain through innovation",
    Benefit4: "Personalized support from A-to-Z",
    Benefit5: "Highest level of expertise in extreme transformation",
    Benefit6: "Tailor-made financing, from acquisition. to managed services, and on-demand models",
    Benefit7: "Control of all major aspects",
    Benefit8: "Data protection",
}

// Large Enterprise

export const largeEnterprise = {
    type: "Large Enterprise",
    captionPara: "The departments of large enterprises face great challenges; they are the essential organs that enable business goals and visions to take shape.",
    
    paraContentTitle: "Executives must meet present business needs and plan for the future, a double task that is difficult to accomplish daily.",
    paraContentText1: "Achieving business objectives in a changing and innovative environment",
    paraContentText2: "Your business needs are the foundation of our work. We begin to understand your realities by listening and discussing, and then bring technology back into the equation.",
    paraContentText3: "With our tailor-made solutions, we offer several ways to keep your data secure. With mobility having become a hot topic, we can facilitate the ability and ease to monitor your employees from anywhere. With virtualized environments, we simplify and modernize work structures, both in terms of growth fluctuations and in terms of resource usage. Automation, networking and streamlining are areas of expertise that we master, and for which we have excellent supply connections with our manufacturers.",
    paraContentText4: "We help you prioritize budgets and efforts, maintain your current environment, even if it remains mixed, non-standardized or complex to manage. We support you in assessing growing needs in relation to your budget and we develop, in collaboration with your teams, new business line support services for the development of new offers. Our ability to understand, advise and concretize your needs allows you to choose the best solutions, to achieve your objectives, and to achieve a faster return on investment. ",
    paraContentText5: "",
    paraContentText6: "",

    quote: "In the aftermath of the COVID-19 crisis, our company had to quickly adapt to remote and efficient ways. Rapigrow has helped us in terms of performance, ergonomics and Structure.",

    randomLink: random1,

    Benefit1: "Increase in productivity ",
    Benefit2: "Price negotiation with manufacturing partners ",
    Benefit3: "Simplification of environments to increase the robustness of infrastructures ",
    Benefit4: "Improved performance and user experience ",
    Benefit5: "Ability to work with a smaller, more versatile team ",
    Benefit6: "Realization of projects in less time, and with more agility ",
    Benefit7: "Control of all major aspects",
    Benefit8: "Streamline the departments",
}

// Solutions Page

export const SolutionsContent = {
    title1: "Business Growth Consulting",
    title2: "HR Transformation",
    title3: "Business Process Re-engineering",

    overview1: "Growth strategy is the protein which runs in the veins of business. RapiGrow holds diversified industry expertise in Channel Sales, Corporate Sales, Direct Sales, Retail Sales and Franchise Sales.",
    key1One: "- Rapid Growth in Turnover.",
    key1Two: "- Nurturing New Avenues for Business Growth by Market Expansion, Market Penetration or Product/Service Line Expansion",
    key1Three: "- Strengthening the Business Development Department.",
    key1Four: "",
    testimonial1: '"Achieved excellent growth in my business by systematic and extraordinary efforts of consulting team."',
    testimonial1By: "- Mayank Jani, MNT Infovision Pvt. Ltd.",

    overview2: "People are the biggest asset of the organization. In today's era of radical, technologically evolving and unpredictable customer-choices, it is important for organizations to invest. Following are the aspects we deal with.",
    key2One: "- Improvement in Retention Ratio.",
    key2Two: "- Intrapreneurship amongst Team",
    key2Three: "- Culture Development & Better Employer Branding.",
    key2Four: "- Enhanced Productivity against the Investment made.",
    testimonial2: '"Professionalism in Team management, Performance and Best Practices of HR is what they have imbibed in my organisation." ',
    testimonial2By: "- Chintan Thakkar, Hindustan Infrastructure (RMC) ",

    overview3: "Bottom line growth by lowest possible cost and increase in profitability is core focus of every organization. Hence, Streamlining Business Processes and Re-Engineering the existing system becomes the priority in order to run the business on Auto-Mode and achieving Operational Excellency",
    key3One: "- Quality Improvement.",
    key3Two: "- Optimizing Resource Utilization.",
    key3Three: "- IT Modernization.",
    key3Four: "- Improvement in Bottom Line - Profitability and Cost Management.",
    testimonial3: '“As a result of process optimization, we experienced a meteoric rise in our process efficiency, thanks to RapiGrow"',
    testimonial3By: "- Sharshal Ambulkar, Vice President, Aditya Birla Group",    
}

// Dragger Clients Content

export const draggerClientsContent = {
    companyTitle1: 'Aditya Birla',
    companyTitle2: 'Chaaswala',
    companyTitle3: 'Clear',
    companyTitle4: 'Hindustan RMC',
    companyTitle5: 'FMS',
    companyTitle6: 'NMIMS',
    companyTitle7: 'Nutron',
    companyTitle8: 'Oberoi',

    companyLogo1: logo1,
    companyLogo2: logo2,
    companyLogo3: logo3,
    companyLogo4: logo4,
    companyLogo5: logo5,
    companyLogo6: logo6,
    companyLogo7: logo7,
    companyLogo8: logo8,
}

export const CardContent = {
    heading1: "Medium Enterprises",
    content1: "To stay competitive, SMEs seek to do more with less, while achieving agility and productivity. Right management techniques offer this advantage.",
    url1: medium,

    heading2: "Large Enterprises",
    content2: "Top level management of MNC’s often need business retention and expansion. A strategically formulated action plan gives them the edge",
    url2: large,

    heading3: "Education",
    content3: "At Rapigrow, we have deep expertise in supporting transformative education initiatives and have served as a thought leader and partner for prominent global education forums.",
    url3: education,

    heading4: "Government",
    content4: "Helping the local government reach the maximum level of efficiency with available resources.",
    url4: government,

    heading5: "Healthcare",
    content5: "To create and deliver strategies to help them navigate complexity, address challenges and achieve their business goals, in this sector which ultimately means improving patient care.",
    url5: health

}

export const AboutText = {
    paraTitle1: "We Aim to drive business growth by valuing human, organizational, and operational intelligence",
    paraLine1: "RapiGrow passionately delivers Advisory and Implementation Services in the area of Management Consulting to clients varying from SME sectors to large Conglomerates. An organization founded by a Techno-Commercial Entrepreneur, who believes that every business can grow rapidly if it has a strong strategic foundation.",
    paraLine2: 'We aim to mark a name in the "To understand, plan and industry by achieving 100% client implement each strategy in a satisfaction',
    paraLine3: "Also aim to understand, plan and implement each strategy in a way that strengthens the client's existing operational capabilities and achieves new heights.",
    paraLine4: "We lay the foundations necessary for your development. We handle consultation, thinking, and the strategic approach. We listen, we understand your reality, and we offer the management solution that will truly work best."
}