import { NavLink } from 'react-router-dom'
import React from 'react'
import './Button.scss'

function Button(props) {
    return (
        <div>
            <div className='nav-cta'>

                <NavLink to="/contact">
                    <button className="talk">
                        <span className='cta-text'>{props.textBtn}</span>
                        {/* <span className='cta-icon'>ic</span> */}
                    </button>
                </NavLink>
            </div>
        </div>
    )
}

export default Button
