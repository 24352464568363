import React from 'react';
import './Benefits.scss'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function Benefits(props) {
  return (
      <div className='benefits'>
          <div className="custom-container">
              <div className="benefits-wrapper">
                  <div className="ben-items">
                      <div className="ben-item">
                          <p>Benefits</p>
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                              <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit1}</p>    
                          </div> 
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                              <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                          </div> 
                            <p>{props.Benefit2}</p>    
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                                <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit3}</p>    
                          </div> 
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                                <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit4}</p>    
                          </div> 
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                              <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit5}</p>    
                          </div> 
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                              <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit6}</p>    
                          </div> 
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                              <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit7}</p>    
                          </div> 
                      </div>

                      <div className="ben-item">
                          <div className="tick">
                              <CheckCircleOutlineIcon/>
                          </div>

                          <div className="ben-text">
                            <p>{props.Benefit8}</p>    
                          </div> 
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Benefits;
