import React, {useEffect} from 'react'
import './HomeHero.scss'
import svg1 from '../../images/header-person_bg.svg'
import { Link } from 'react-router-dom'
import gsap from 'gsap/all'
import ScrollTrigger from 'gsap/ScrollTrigger'

function HomeHero() {

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo('.svg-one', {
            yPercent: '20',
            
        },{
            yPercent: '-20',
            scrollTrigger:{
                trigger: '#home-hero',
                scrub: true,
        }
    })
    }, [] );

    return (
        <div id="home-hero">
            <div className="home-hero">
                <div className="container-fluid">
                <img className='svg-one' src={svg1} alt="" />
                <div className="custom-row">
                    <div className="hero-left__wrapper">
                        <img  src="https://iti.ca/uploads/2020/07/header-person_06.png" alt="" />
                    </div>

                    <div className="home-hero__content">

                        <div className="hero-content__wrapper">

                            <div className="hero-inner-content">
                                <h1>
                                    <span className='line'>We're<br/></span>
                                    <span className='line'>Humans<br/></span>
                                    <span className='line'>& Brand<br/></span>
                                    <span className='line'>Experts<br/></span>
                                    
                                </h1>

                                <p className="hero-tagline">
                                Rapigrow excels in delivering the best business development and management consultancy Pan India.
                                </p>
                            </div>
                            
                            <div className="buttons-wrapper">
                                <div className='nav-cta'>
                                    <Link to="/contact">
                                        <button className="talk">
                                            <span className='cta-text'>Let's Talk</span>
                                            {/* <span className='cta-icon'>ic</span> */}
                                        </button>
                                    </Link>
                                    
                                </div>

                                {/* <div className='nav-cta'>
                                    <button className="talk">
                                        <span className='cta-text'>Let's Talk</span>
                                        <span className='cta-icon'>c</span>
                                    </button>
                                </div> */}

                            </div>
                        </div>            
                    </div>
                 </div>
                    
                </div>
            </div>
        </div>
    )
}

export default HomeHero
