import React from 'react';
import './ContactForm.scss'
import { TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SolutionsHero from '../SolutionsHero/SolutionsHero';

function ContactForm() {
  return (
      <div>
          <SolutionsHero line1="Reach Out" line2="To Us" link="#"/>
        <div className='contact-form'>
            <div className="custom-container">
                <div className="form-container">
                <div className="form-main">

                <h1>Let's Talk</h1>

                <Box component="form" id="form">

                    <div className="row form-row">
                        <div className="col-6">
                            <TextField id="outlined-basic" label="First Name" variant="filled" required />
                        </div>

                        <div className="col-6">
                            <TextField id="outlined-basic" label="Last Name" variant="filled" required />
                        </div>
                    </div>

                    <div className="row form-row">
                        <div className="col-6">
                            <TextField id="outlined-basic" label="Industry" variant="filled" />
                        </div>

                        <div className="col-6">
                            <TextField id="outlined-basic" label="Organisation" variant="filled" />
                        </div>
                    </div>

                    <div className="row form-row">
                        <div className="col-6">
                            <TextField id="outlined-basic" label="Number" type="number" variant="filled" required/>
                        </div>

                        <div className="col-6">
                            <TextField id="outlined-basic" label="Email" type="email" variant="filled" required/>
                        </div>
                    </div>

                    <div className="row form-row">
                        <div className="col-12">
                            <TextField id="outlined-basic" label="Message" multiline rows={4} variant="filled" required/>
                        </div>
                    </div>
                    
                    <Button variant="contained" endIcon={<SendIcon />}>
                        Send
                    </Button>
                </Box>
                </div> 
                </div>
                
            </div>
        </div>
      </div>
  );
}

export default ContactForm;
