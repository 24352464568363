import React from 'react';
import './ParaContent.scss'

function ParaContent(props) {
  return (
  <div className='para-content'>
      <div className="para-content__wrapper">
            <div className="para-content__inner">
                <div className="para-content__title">
                    <h3>
                       {props.paraContentTitle}
                    </h3>
                </div>

                <div className="para-content__content">
                    <p>{props.paraContentLine1}</p>
                    <p>{props.paraContentLine2}</p>
                    <p>{props.paraContentLine3}</p>
                    <p>{props.paraContentLine4}</p>
                    <p>{props.paraContentLine5}</p>
                    <p>{props.paraContentLine6}</p>
                    
                </div>
            </div>
      </div>
  </div>
  )
}

export default ParaContent;
