import React , {useEffect} from 'react'
import './Dragger.scss'
import Card from './Card/Card'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import gsap from 'gsap/all'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { CardContent } from '../Data';

function Dragger() {

  useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo('.svg-arrow', {
      yPercent: '30'
    }, {

      yPercent: '-30',

      scrollTrigger: {
        trigger: '#draggable',
        scrub: true
      }
    })


  }, [])

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
          slidesToSlide: 1,         
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

   
      const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
          
        const { carouselState: { currentSlide } } = rest;
        
        return (
          <div className="carousel-button-group">
        
            <div className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} >
                <button className="c-slider_button -prev" data-slider="prev" tabIndex="0" role="button" aria-label="Previous slide" aria-disabled="false">
                    <ChevronLeftIcon/>
                </button>
            </div>
            
            
            <div onClick={() => next()}>
              <button className="c-slider_button -next" data-slider="next" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">
                  <ChevronRightIcon/>
              </button>
            </div>
            
          </div>
        );
      };

    return (
      
        <div id="draggable">

            <svg className='svg-arrow'>
              <path fillRule="evenodd" d="M69.1862621 1.98707403L54.5125195 16.3272875C51.8014662 18.9767196 51.8010072 23.2726837 54.5120605 25.9216672L120.202439 90.118926C122.913492 92.768358 127.308914 92.768358 130.019967 90.118926L144.69371 75.778264C147.404763 73.1288319 147.404763 68.8333163 144.69371 66.1843328L79.0037907 1.98707403C76.2927374-.662358009 71.8973154-.662358009 69.1862621 1.98707403M50.827549 29.8396176L65.4470038 44.2270541C68.1480272 46.8852109 68.1484845 51.1953218 65.4474611 53.8530286L26.4268869 92.2547762C23.7258635 94.9124829 19.346703 94.9124829 16.6456796 92.2543262L2.02576754 77.8668896C-.675255847 75.2087329-.675255847 70.8990719 2.02576754 68.2409152L41.0463417 29.8396176C43.7473651 27.1814608 48.1265256 27.1814608 50.827549 29.8396176M83.0952494 64.26L62.5612047 64.26C58.7673837 64.26 55.692 67.3113472 55.692 71.0750659L55.692 193.461484C55.692 197.225653 58.7673837 200.277 62.5612047 200.277L83.0952494 200.277C86.8890704 200.277 89.964 197.225653 89.964 193.461484L89.964 71.0750659C89.964 67.3113472 86.8890704 64.26 83.0952494 64.26">
              </path>
            </svg>
            
            <div className="dragger-container">
                
                <div className="dragger-caption">
                    <p>Industries</p>
                </div>
                

                <div className="dragger-title">
                    <h1>We advise more than 1500</h1>
                    <h1>companies from all sectors</h1>
                </div>

                <div className='my-own-custom-container'>
                    <Carousel responsive={responsive} arrows={false} customButtonGroup={<ButtonGroup />} renderButtonGroupOutside>
                        <Card heading={CardContent.heading1} content={CardContent.content1} url={CardContent.url1}/>
                        <Card heading={CardContent.heading2} content={CardContent.content2} url={CardContent.url2}/>
                        <Card heading={CardContent.heading3} content={CardContent.content3} url={CardContent.url3}/>
                        <Card heading={CardContent.heading4} content={CardContent.content4} url={CardContent.url4}/>
                        <Card heading={CardContent.heading5} content={CardContent.content5} url={CardContent.url5}/>
                    </Carousel>    
                </div>

            </div>
            
        </div>
    )
}

export default Dragger
