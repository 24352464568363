import React from 'react'
import Navbar from '../Components/Navbar/Navbar';
import HomeHero from '../Components/HomeHero/HomeHero';
import Industry from '../Components/Industry/Industry';
import Dragger from '../Components/Dragger/Dragger';
import TextScroller from '../Components/TextScroller/TextScroller';
import Accordion from '../Components/Accordion/Accordion'
import Footer from '../Components/Footer/Footer';

function Home() {
    return (
        <div id="home">
            <Navbar/>
            <HomeHero/>
            <Industry/>
            <Dragger/>
            <TextScroller/>
            <Accordion/>
            {/* <DraggerClients/> */}
            <Footer/>
        </div>
    )
}

export default Home
