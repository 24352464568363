import React from 'react'

function Services() {
    return (
        <div id="services">
           <h1>Services</h1> 
        </div>
    )
}

export default Services
