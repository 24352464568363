import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'
function Footer() {
    return (
        <div id="footer">
            <div className="footer">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="newsletter-wrapper">
                                <p>
                                    Rapigrow Newsletter
                                </p>

                                <h1>Rapigrow Newsletter</h1>

                                <div className="input-wrapper">
                                    <input type="email" placeholder='Your email id'/>
                                    <button>Send</button>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="links-wrapper">
                                <div className="top-links-list">
                                    <p>Business Solutions</p>
                                    <p>HR Solutions</p>
                                    <p>IT Solutions</p>
                                    <p>Growth Management</p>
                                </div>

                                <div className="d-flex">
                                    <div className="tnc-links">
                                        <div className='link-title'>Other Links</div>
                                        <p>Our Portfolio</p>
                                        <p>Terms and Conditions</p>
                                        <p>Privacy Policy</p>
                                    </div>

                                    <div className="industries-list">
                                        <div className='link-title'>Industries</div>
                                        <Link to="/medium-enterprise"><p>Medium Enterprise</p></Link>
                                        
                                        <Link to="/large-enterprise"><p>Large Enterprise</p></Link>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="row last-row">
                        <div className="col-12 col-lg-5">
                            <div className="social-share-icons">

                            </div>
                        </div>

                        <div className="col-12 col-lg-7">
                            <div className="contact-list">
                                <p>Mobile<br/>+91-8000612444</p>
                                <p>Email<br/>harshal.banker@rapigrow.com</p>
                                <p>Address<br/>H 1112, Titanium City Center, 100ft Anandnagar Road, Satellite, Ahmedabad - 380015</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
