import React from 'react';
import './SimpleAccordion.scss'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '../Button/Button'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function SimpleAccordion(props) {

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
    
  return (
      <div className='simple-accordion'>

          <div className="custom-container">
              <div className="accordion-main">
                <h1>{props.TitleAcc}</h1>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Overview</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="acc-card">
                          <div className="row">
                              <div className="col-12 col-md-4 d-flex position-relative">
                                  <div className="acc-card-content">
                                  <p>
                                      {props.overview}
                                  </p>
                                  <Button textBtn="Let's Talk"/>
                                  </div>
                              </div>

                              <div className="col-12 col-md-7 offset-md-1">
                              <div className="acc-card-img">
                                  <img src="https://iti.ca/uploads/2020/06/acquisition-talents-thumb.jpg" alt="" />
                              </div>
                              </div>
                          </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Key Result Area</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>{props.key1}</p>
                        <p>{props.key2}</p>
                        <p>{props.key3}</p>
                        <p>{props.key4}</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Testimonial</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <h3>
                          {props.testimonial}
                        </h3>
                        <p className="testimonialBy">{props.testimonialBy}</p>
                    </AccordionDetails>
                </Accordion>
              </div>
          </div>
           
      </div>
  );
}

export default SimpleAccordion;
