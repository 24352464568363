import React from 'react';
import Benefits from '../Components/Benefits/Benefits';
import Experts from '../Components/Experts/Experts';
import IndustryHero from '../Components/IndustryHero/IndustryHero';
import Navbar from '../Components/Navbar/Navbar';
import ParaContent from '../Components/ParaContent/ParaContent.js';
import Quote from '../Components/Quote/Quote';
import RandomImg from '../Components/RandomImg/RandomImg';
import Accordion from '../Components/Accordion/Accordion'
import Footer from '../Components/Footer/Footer'
import { smallEnterprise } from '../Components/Data';
import ScrollToTop from '../Components/ScrollToTop';
import medium from '../images/small-ent.jpg'

function SmallEnterprise() {
  return (
      <div>
       
        <Navbar/>
        <IndustryHero typeIndustry={smallEnterprise.type} captionPara={smallEnterprise.captionPara} url={medium}/>
        <ParaContent paraContentTitle={smallEnterprise.paraContentTitle} paraContentLine1={smallEnterprise.paraContentText1}
            paraContentLine2={smallEnterprise.paraContentText2}
            paraContentLine3={smallEnterprise.paraContentText3}
            paraContentLine4={smallEnterprise.paraContentText4}
            paraContentLine5={smallEnterprise.paraContentText5}
            paraContentLine6={smallEnterprise.paraContentText6}
       
        />
        <Quote quote={smallEnterprise.quote}/>
        <RandomImg randomLink={smallEnterprise.randomLink}/>
        <Benefits Benefit1={smallEnterprise.Benefit1} 
            Benefit2={smallEnterprise.Benefit2} 
            Benefit3={smallEnterprise.Benefit3} 
            Benefit4={smallEnterprise.Benefit4} 
            Benefit5={smallEnterprise.Benefit5} 
            Benefit6={smallEnterprise.Benefit6} 
            Benefit7={smallEnterprise.Benefit7} 
            Benefit8={smallEnterprise.Benefit8} 
        />
        <Accordion/>
        <Experts/>
        <Footer/>
      </div>
  );
}

export default SmallEnterprise;
