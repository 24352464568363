import React from 'react';
import SolutionsHero from '../Components/SolutionsHero/SolutionsHero';
import Navbar from '../Components/Navbar/Navbar';
import SimpleAccordion from '../Components/SimpleAccordion/SimpleAccordion';
import DraggerClients from '../Components/DraggerClients/DraggerClients'
import Footer from '../Components/Footer/Footer'
import ScrollToTop from '../Components/ScrollToTop';
import { SolutionsContent } from '../Components/Data';

function Solutions() {
  return (
      <div>
          <Navbar/>
          <div style={{backgroundColor: '#E1EFF6' , paddingBottom: '1rem', marginBottom: '6.6667rem'}}>
            <SolutionsHero line1="Our" line2="Business" line3="Solutions"/>
            <SimpleAccordion TitleAcc={SolutionsContent.title1} overview={SolutionsContent.overview1} key1={SolutionsContent.key1One} key2={SolutionsContent.key1Two}
              key3={SolutionsContent.key1Three} testimonial={SolutionsContent.testimonial1} testimonialBy={SolutionsContent.testimonial1By}
            />
            <SimpleAccordion TitleAcc={SolutionsContent.title2} overview={SolutionsContent.overview2} key1={SolutionsContent.key2One} key2={SolutionsContent.key2Two}
            key3={SolutionsContent.key2Three} key4={SolutionsContent.key2Four} testimonial={SolutionsContent.testimonial2} testimonialBy={SolutionsContent.testimonial2By}
            />
            <SimpleAccordion TitleAcc={SolutionsContent.title3}
            overview={SolutionsContent.overview3} key1={SolutionsContent.key3One} key2={SolutionsContent.key3Two}
            key3={SolutionsContent.key3Three} key4={SolutionsContent.key3Four} testimonial={SolutionsContent.testimonial3} testimonialBy={SolutionsContent.testimonial3By}/>
          </div> 
          {/* <DraggerClients/> */}
          <Footer/>
      </div>
  );
}

export default Solutions;
