import React, {useEffect} from 'react';
import './IndustryHero.scss' 
import Button from '../Button/Button'
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

function IndustryHero(props) {

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 960px)": function(){
                gsap.fromTo('.svg-plus-2',{
                    top: '200%'
                }, {
                    top: '-220%',
                    scrollTrigger: {
                        trigger: '.industry-hero',
                        scrub: true,
                    }
                })
            }
        })

        
    }, [])

  return (
      <div className='industry-hero'>
          <div className="industry-hero__header">
              <div className="row">
                  <div className="col-12 col-md-3">
                      <div className="industry-hero__header-caption">
                          <p>Industry</p>
                      </div>
                  </div>

                  <div className="col-12 col-md-9">
                      <div className="industry-hero__header-title">
                          <h1>{props.typeIndustry}</h1>
                      </div>
                  </div>
              </div>
          </div>

          <div className="industry-hero__button">
              <div className="row">
                  <div className="col-md-9 offset-lg-3 d-flex position-relative">
                      <div className="ind-button">
                        <Button textBtn="Let's Talk"/>
                      </div>

                    <div className="svg-plus-2">
                        <svg className='svg-plus' viewBox='0 0 100 100'>
                
                            <path d="M55.39,63.54H44.61c-1.99,0-3.6,1.61-3.6,3.6V95.9c0,1.99,1.61,3.6,3.6,3.6h10.77c1.99,0,3.6-1.61,3.6-3.6V67.14C58.99,65.15,57.38,63.54,55.39,63.54"></path>
                            <path d="M36.46,43.82V54.6c0,1.99-1.61,3.6-3.6,3.6H4.1c-1.99,0-3.6-1.61-3.6-3.6V43.82c0-1.99,1.61-3.6,3.6-3.6h28.76C34.85,40.22,36.46,41.83,36.46,43.82"></path>
                            <path d="M63.54,43.82V54.6c0,1.99,1.61,3.6,3.6,3.6H95.9c1.99,0,3.6-1.61,3.6-3.6V43.82c0-1.99-1.61-3.6-3.6-3.6H67.14C65.15,40.22,63.54,41.83,63.54,43.82"></path>
                            <path d="M55.39,36.46H44.61c-1.99,0-3.6-1.61-3.6-3.6V4.1c0-1.99,1.61-3.6,3.6-3.6h10.77c1.99,0,3.6,1.61,3.6,3.6v28.76C58.99,34.85,57.38,36.46,55.39,36.46"></path>
                    
                        </svg>
                    </div>
                  </div>
              </div>
             
          </div>

          <div className="industry-hero__body">
              <div className="row">
                  <div className="col-12 col-lg-3 order-2 order-lg-1">
                     <div className="industry-hero__body-content">
                         <p>
                             {props.captionPara}
                         </p>
                     </div>
                  </div>

                  <div className="col-12 col-lg-9 order-1 order-lg-2">
                     <div className="industry-hero__parallax">
                        <img src={props.url} alt="" />
                     </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default IndustryHero;
