import React from 'react';
import Benefits from '../Components/Benefits/Benefits';
import Experts from '../Components/Experts/Experts';
import IndustryHero from '../Components/IndustryHero/IndustryHero';
import Navbar from '../Components/Navbar/Navbar';
import ParaContent from '../Components/ParaContent/ParaContent.js';
import Quote from '../Components/Quote/Quote';
import RandomImg from '../Components/RandomImg/RandomImg';
import Accordion from '../Components/Accordion/Accordion'
import Footer from '../Components/Footer/Footer'
import { largeEnterprise } from '../Components/Data';
import large from '../images/large-ent.jpg'

function LargeEnterprise() {
  return (
      <div>
        <Navbar/>
        <IndustryHero typeIndustry={largeEnterprise.type} captionPara={largeEnterprise.captionPara} url={large}/>
        <ParaContent paraContentTitle={largeEnterprise.paraContentTitle} paraContentLine1={largeEnterprise.paraContentText1}
            paraContentLine2={largeEnterprise.paraContentText2}
            paraContentLine3={largeEnterprise.paraContentText3}
            paraContentLine4={largeEnterprise.paraContentText4}
            paraContentLine5={largeEnterprise.paraContentText5}
            paraContentLine6={largeEnterprise.paraContentText6}
       
        />
        <Quote quote={largeEnterprise.quote}/>
        <RandomImg randomLink={largeEnterprise.randomLink}/>
        <Benefits Benefit1={largeEnterprise.Benefit1} 
            Benefit2={largeEnterprise.Benefit2} 
            Benefit3={largeEnterprise.Benefit3} 
            Benefit4={largeEnterprise.Benefit4} 
            Benefit5={largeEnterprise.Benefit5} 
            Benefit6={largeEnterprise.Benefit6} 
            Benefit7={largeEnterprise.Benefit7} 
            Benefit8={largeEnterprise.Benefit8} 
        />
        <Accordion/>
        <Experts/>
        <Footer/>
      </div>
  );
}

export default LargeEnterprise;
