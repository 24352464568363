import React from 'react'
import ClientCard from './ClientCard/ClientCard'
import './DraggerClients.scss'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { draggerClientsContent } from '../Data';

function DraggerClients() {

    const responsiveDraggerClients = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1,         
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
          
        const { carouselState: { currentSlide } } = rest;
        
        return (
          <div className="carousel-button-group" >
        
            <div className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} >
                <button className="c-slider_button -prev" data-slider="prev" tabIndex="0" role="button" aria-label="Previous slide" aria-disabled="false">
                    <ChevronLeftIcon/>
                </button>
            </div>
            
            
            <div onClick={() => next()}>
              <button className="c-slider_button -next" data-slider="next" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">
                  <ChevronRightIcon/>
              </button>
            </div>
            
          </div>
        );
      };


    return (
        <div id="dragger-clients">
            <div className="dragger-container">
                <div className="dragger-caption">
                    <p>Industries</p>
                </div>
                
                <div className="dragger-title">
                    <h1>We advise more than 1500</h1>
                    <h1>companies from all sectors</h1>
                </div>
                
                <div className="client-card-wrapper">
                    <Carousel arrows={false} responsive={responsiveDraggerClients} customButtonGroup={<ButtonGroup />} renderButtonGroupOutside>
                        <ClientCard image={draggerClientsContent.companyLogo1} name={draggerClientsContent.companyTitle1}/>
                        <ClientCard image={draggerClientsContent.companyLogo2} name={draggerClientsContent.companyTitle2}/>
                        {/* <ClientCard image={draggerClientsContent.companyLogo3} name={draggerClientsContent.companyTitle3}/> */}
                        <ClientCard image={draggerClientsContent.companyLogo4} name={draggerClientsContent.companyTitle4}/>
                        <ClientCard image={draggerClientsContent.companyLogo5} name={draggerClientsContent.companyTitle5}/>
                        <ClientCard image={draggerClientsContent.companyLogo6} name={draggerClientsContent.companyTitle6}/>
                    </Carousel>
                </div>
            </div> 
        </div>
    )
}

export default DraggerClients
