import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './Accordion.scss'
import Button from '../Button/Button'
import gsap from 'gsap/all'
import ScrollTrigger from 'gsap/ScrollTrigger'
import first from '../../images/first-service.jpg'
import second from '../../images/second-service.jpg'
import third from '../../images/third-service.jpg'
import fourth from '../../images/fourth-service.jpg'
import fifth from '../../images/fifth-service.jpg'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {

  useEffect(()=>{

    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo('.squarish-svg', {
      y: '80px'
    }, {
      y: '-15px',
      scrollTrigger:{
        trigger: '.left-half',
        scrub: true,
      }
    })

  }, [])

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id="accordion">

      <div className="accordion-row">
        <div className="left-half">

            <div>
              <h1>
                Rapigrow <br/>Solutions
              </h1>

              <Button textBtn="Let's Talk"/>
            </div>

            <div>
              <svg role="img" className='squarish-svg'>
                <path d="M106.491 66.893l11.862 11.862a5.612 5.612 0 010 7.938l-31.66 31.66a5.612 5.612 0 01-7.938 0l-11.862-11.862a5.613 5.613 0 010-7.936l31.662-31.662a5.613 5.613 0 017.936 0zm-92.984 0a5.613 5.613 0 017.936 0l31.662 31.662a5.613 5.613 0 010 7.936l-11.863 11.862a5.612 5.612 0 01-7.937 0l-31.66-31.66a5.612 5.612 0 010-7.938zM78.505 1.644a5.612 5.612 0 017.938 0l31.66 31.66a5.612 5.612 0 010 7.938l-11.862 11.863a5.613 5.613 0 01-7.936 0L66.643 21.443a5.613 5.613 0 010-7.936zm-37.263 0l11.863 11.863a5.613 5.613 0 010 7.936L21.443 53.105a5.613 5.613 0 01-7.936 0L1.644 41.242a5.612 5.612 0 010-7.937l31.66-31.66a5.612 5.612 0 017.938 0z"></path>
              </svg>
            </div>
            
        </div>  

        <div className="right-half">

        </div>
      </div>

      <div className="custom-container">
        <div className="accordion-main">
        
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Business Growth Consultation</Typography>
          </AccordionSummary>
          <AccordionDetails>
              <div className="acc-card">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex position-relative">
                      <div className="acc-card-content">
                        <p>
                        Growth strategy is the protein which runs in the veins of business. RapiGrow holds diversified industry expertise in Channel Sales, Corporate Sales, Direct Sales, Retail Sales and Franchise Sales
                        </p>
                        <Button textBtn="Let's Talk"/>
                      </div>
                  </div>

                  <div className="col-12 col-md-7 offset-md-1">
                    <div className="acc-card-img">
                        <img src={first} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>HR Transformation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="acc-card">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex position-relative">
                      <div className="acc-card-content">
                        <p>
                        People are the biggest asset of the organization. In today's era of radical, technologically evolving and unpredictable customer-choices, it is important for organizations to invest. We deal with aspects like Organization structure, HR policies and process, Competency mapping and many more.
                        </p>
                        <Button textBtn="Let's Talk"/>
                      </div>
                  </div>

                  <div className="col-12 col-md-7 offset-md-1">
                    <div className="acc-card-img">
                        <img src={second} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary aria-controls="panel64-content" id="panel6d-header">
            <Typography>Finance Consultancy</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="acc-card">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex position-relative">
                      <div className="acc-card-content">
                        <p>
                          Our financial consultation services offer comprehensive and organized advice for all financial aspects of your business. Our team collaborates closely with all stakeholders in your organization to address vital financial matters.
                        </p>
                        <Button textBtn="Let's Talk"/>
                      </div>
                  </div>

                  <div className="col-12 col-md-7 offset-md-1">
                    <div className="acc-card-img">
                        <img src={fifth} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Business Process Re-engineering </Typography>
          </AccordionSummary>
          <AccordionDetails>
             <div className="acc-card">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex position-relative">
                      <div className="acc-card-content">
                        <p>
                        Bottom line growth by lowest possible cost and increase in profitability is core focus of every organization. Hence, Streamlining Business Processes and Re-Engineering the existing system becomes the priority in order to run the business on Auto-Mode and achieving Operational Excellence.
                        </p>
                        <Button textBtn="Let's Talk"/>
                      </div>
                  </div>

                  <div className="col-12 col-md-7 offset-md-1">
                    <div className="acc-card-img">
                        <img src={third} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4-content" id="panel4d-header">
            <Typography>Market Research</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="acc-card">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex position-relative">
                      <div className="acc-card-content">
                        <p>
                        In-depth understanding about Market and Customers are the master key for the organization to design your Short-term and Long-term Business Plan. Market research is fuel for Innovations in the organization.s
                        </p>
                        <Button textBtn="Let's Talk"/>
                      </div>
                  </div>

                  <div className="col-12 col-md-7 offset-md-1">
                    <div className="acc-card-img">
                        <img src={fourth} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls="panel54-content" id="panel5d-header">
            <Typography>Website & Application Development </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="acc-card">
                <div className="row">
                  <div className="col-12 col-md-4 d-flex position-relative">
                      <div className="acc-card-content">
                        <p>
                        Websites & applications are like a digital identity for your business. In today’s consumer market, websites are a big asset as any customer’s first impression is your brand’s digital identity when they search for you on google. Having a good website helps you generate leads.
                        </p>
                        <Button textBtn="Let's Talk"/>
                      </div>
                  </div>

                  <div className="col-12 col-md-7 offset-md-1">
                    <div className="acc-card-img">
                        <img src={fifth} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </AccordionDetails>
        </Accordion>

        
        </div>
      </div>
    </div>
  );
}