import React,{useEffect} from 'react';
import './Quote.scss';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

function Quote(props) {

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);

        ScrollTrigger.matchMedia({
            "(min-width: 960px)": function(){
                gsap.fromTo('#quote',{
                    top: '40%'
                }, {
                    top: '-20%',
                    scrollTrigger: {
                        trigger: '.quote',
                        
                        scrub: true,
                    }
                })
            }
        })

       
    }, [])
  return (
      <div className='quote'>
          <div className="custom-container">
             <div className="quote-wapper">
                 <div className="blockquote-wrapper">
                    <svg id="quote">
                       
                        <path d="M13.0975037,4.80032357 L0.991666084,16.6309997 C-1.24495292,18.8167811 -1.24533156,22.3609515 0.991287445,24.5463629 L55.1858495,77.5091014 C57.4224685,79.6948829 61.0486916,79.6948829 63.2853106,77.5091014 L75.3911482,65.6780553 C77.6277673,63.4922738 77.6277673,59.9484735 75.3911482,57.7630621 L21.1969649,4.80032357 C18.9603459,2.61454214 15.3341227,2.61454214 13.0975037,4.80032357" transform="translate(38.191313, 41.154713) rotate(-90.000000) translate(-38.191313, -41.154713) "></path>
                        <g transform="translate(85.141425, 61.785988) scale(1, -1) translate(-85.141425, -61.785988) translate(46.141425, 0.785988)">
                            <path d="M12.9584912,3.28933607 L0.852653584,15.1200122 C-1.38396542,17.3057936 -1.38434406,20.849964 0.852274945,23.0353754 L55.046837,75.9981139 C57.283456,78.1838954 60.9096791,78.1838954 63.1462981,75.9981139 L75.2521357,64.1670678 C77.4887548,61.9812863 77.4887548,58.437486 75.2521357,56.2520746 L21.0579524,3.28933607 C18.8213334,1.10355464 15.1951102,1.10355464 12.9584912,3.28933607" transform="translate(38.052300, 39.643725) rotate(-90.000000) translate(-38.052300, -39.643725) "></path>
                            <path d="M64.5224654,68.237497 L76.5835156,80.1071322 C78.8118599,82.3001115 78.8122372,85.855953 76.5838929,88.0485611 L44.3919192,119.730003 C42.1635749,121.922611 38.5507675,121.922611 36.3224232,119.729632 L24.2609957,107.859996 C22.0326514,105.667017 22.0326514,102.111547 24.2609957,99.9185675 L56.4529694,68.237497 C58.6813137,66.0445177 62.2941211,66.0445177 64.5224654,68.237497" transform="translate(50.422350, 93.983587) rotate(-90.000000) translate(-50.422350, -93.983587) "></path>
                        </g>
                        <path d="M64.6614779,69.7484845 L76.7225281,81.6181197 C78.9508724,83.811099 78.9512497,87.3669405 76.7229054,89.5595486 L44.5309317,121.24099 C42.3025874,123.433598 38.68978,123.433598 36.4614357,121.240619 L24.4000082,109.370984 C22.1716639,107.178005 22.1716639,103.622534 24.4000082,101.429555 L56.5919819,69.7484845 C58.8203262,67.5555052 62.4331336,67.5555052 64.6614779,69.7484845" transform="translate(50.561362, 95.494575) rotate(-90.000000) translate(-50.561362, -95.494575) "></path>  
               
                    </svg>
                 </div>

                 <div className="mainquote-wrapper">
                     <h2>
                        {props.quote}
                     </h2>
                 </div>
             </div>
          </div>
      </div>
  );
}

export default Quote;
