import './App.scss';
import React from "react";
import '../node_modules/bootstrap/scss/bootstrap.scss'
import { Route, Switch } from "react-router";
import Services from './Pages/Services';
import Home from './Pages/Home';
import About from './Pages/About';
import Industry from './Pages/Industry';
import Solutions from './Pages/Solutions';
import Contact from './Pages/Contact';
import SmallEnterprise from './Pages/SmallEnterprise';
import ScrollToTop from './Components/ScrollToTop'
import LargeEnterprise from './Pages/LargeEnterprise';
import {BrowserRouter} from 'react-router-dom'

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop/>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/services" exact component={Services}/>
                <Route path="/about" exact component={About}/>
                <Route path="/medium-enterprise" exact component={SmallEnterprise}/>
                <Route path="/large-enterprise" exact component={LargeEnterprise}/>
                <Route path="/solutions" exact component={Solutions}/>
                <Route path="/contact" exact component={Contact}/>
              </Switch>
        </BrowserRouter>
          
    </div>
          
  );
}

export default App;
