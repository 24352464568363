import React, { useState, useEffect } from 'react'
import './Navbar.scss'
import logo from '../../images/Logos/logo-final.png'
import { Link, NavLink } from 'react-router-dom'
import gsap from 'gsap'

function Navbar() {
    
    useEffect(()=>{
        const industry = document.querySelector('.industries');
        const indWidth = industry.getBoundingClientRect().left;
        const dropLinks = document.querySelector('.drop-links')

        dropLinks.style.left = `${indWidth}px`
    }, [])

    const [isOpen, setOpen] = useState(false);
    const [isMenu, setMenu] = useState(false);

    const toggleMenu = () => setMenu(!isMenu);

    if(isMenu) {
        gsap.timeline().to('.responsive-nav',{
            top: '0%',
            duration: 0.2
        }).to('.resp-nav-wrapper', {
            opacity: 1
        }, 0.5)
    }

    if(!isMenu) {
        gsap.timeline().to('.resp-nav-wrapper', {
            opacity: 0
        }).to('.responsive-nav',{
            top: '-100%',
            duration: 0.2
        }, 0.5)
    }

    const toggleDrop = () => setOpen(!isOpen);

    if(isOpen) {
        gsap.timeline().to('.dropdown-menu', {
            top: '0%'
        }).to('.drop-link', {
            opacity: 1,
        })
    }

    if(!isOpen){
        gsap.timeline().to('.drop-link', {
            opacity: 0,

        }).to('.dropdown-menu ', {
            top: '-30vh'
        })
    }

    return (
        <div id="navbar">
            <div className="navbar-wrapper">
                
                <header className='main-header'>
                    <div className="logo">

                        <NavLink to="/">
                             <img src={logo} alt="" />
                        </NavLink>

                    </div>

                    <nav>   
                        <ul className='nav-links'>
                            <li><p><NavLink to="/about">About</NavLink></p></li>
                            <li><p><NavLink to="/solutions">Solutions</NavLink></p></li>
                            <li className='industries' onClick={toggleDrop}><p>Industries</p>
                                <div className="dropdown-menu">
                                    <div className="drop-links">
                                    <div className="drop-link">
                                        <NavLink to="/medium-enterprise">Medium Enterprise</NavLink>
                                    </div>

                                    <div className="drop-link">
                                        <NavLink to="/large-enterprise">Large Enterprise</NavLink>
                                    </div> 
                                </div>
                            </div>
                            </li>
                            <li><p><NavLink to="/contact">Contact</NavLink></p></li>
                        </ul>
                        
                            <div className='nav-cta'>
                                <Link to="/contact">
                                <button className="talk">
                                    <span className='cta-text'>Let's Talk</span>
                                </button>
                                </Link>
                            </div>
                       
                    </nav>

                        <div className="btn-nav-wrapper" onClick={toggleMenu}>
                            <div className="btn-nav">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                </header>
                
            </div>

            <div className="responsive-nav">
                <div className="resp-nav-wrapper">
                    <ul>
                        <li>
                            <NavLink to="/about">About</NavLink>                   
                        </li>

                        <li>
                            <NavLink to="/solutions">Solutions</NavLink>                   
                        </li>
                        
                        <li>
                            <NavLink to="/contact">Contact</NavLink>                   
                        </li>
                    </ul>

                    <div className="industries-wrapper">
                        <p>Industries</p>

                        <div className="ind-list-wrapper">
                            <div className="width-50">
                                <Link to="/small-enterprise">
                                    <p>Small Enterpries</p>
                                </Link>
                                <Link to="/large-enterprise">
                                    <p>Large Enterprise</p>
                                </Link>
                                
                            </div>
                        </div>
                    </div>

                    <div className="contact-info">
                        <p>Contact Info</p>

                        <ul>
                            <li>
                                <p>Phone:</p>
                                <p className='color-blue'>+91-8000612444</p>
                            </li>

                            <li>
                                <p>Email:</p>
                                <p className='color-blue'>harshal.banker@rapigrow.com</p>
                            </li>

                            <li>
                                <p>Address:</p>
                                <p className='color-blue'>H-1112 Titanium City Centre,<br/>
                                    Ahmedabad
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Navbar
