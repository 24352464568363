import React, {useEffect} from 'react'
import Button from '../Button/Button'
import './TextScroller.scss'
import gsap from 'gsap/all'
import ScrollTrigger from 'gsap/ScrollTrigger'

function TextScroller() {

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);    
        
        gsap.timeline(
            {
                scrollTrigger:{
                    trigger: '.text-inner__wrapper',
                    
                    scrub: true
                }
            }
        ).fromTo('.understand h1',{
            xPercent: '-10'

        }, {
            xPercent: '0',
        }, 0).fromTo('.advice h1', {
            xPercent: '10'
        }, {
            xPercent: '-10'
        }, 0).fromTo('.shape h1', {
            xPercent: '20'
        }, {
            xPercent: '-2'
        }, 0).to('.svg-wrapper', {
            yPercent: '-15'
        },0).fromTo('.shape svg', {
            yPercent: '50'
        },{
            yPercent: '-50'
        },0)

      }, [])
    return (
        <div id="text-scroller">
            <div className="custom-container">
                <div className="text-inner__wrapper">
                    <div className="understand">
                        <h1>Understand</h1>
                        <div className="svg-wrapper">
                            <svg viewBox="0 0 163 41" className='svg-dash'>
                                
                                <rect width="163" height="41" x="0" y="0" fillRule="evenodd" rx="8"></rect>
                    
                            </svg>
                        </div>
                    </div>

                    <div className="advice">
                        <h1>Advice</h1>                        
                    </div>

                    <div className="shape">

                        <svg className='svg-plus' viewBox='0 0 100 100'>
            
                            <path d="M55.39,63.54H44.61c-1.99,0-3.6,1.61-3.6,3.6V95.9c0,1.99,1.61,3.6,3.6,3.6h10.77c1.99,0,3.6-1.61,3.6-3.6V67.14C58.99,65.15,57.38,63.54,55.39,63.54"></path>
                            <path d="M36.46,43.82V54.6c0,1.99-1.61,3.6-3.6,3.6H4.1c-1.99,0-3.6-1.61-3.6-3.6V43.82c0-1.99,1.61-3.6,3.6-3.6h28.76C34.85,40.22,36.46,41.83,36.46,43.82"></path>
                            <path d="M63.54,43.82V54.6c0,1.99,1.61,3.6,3.6,3.6H95.9c1.99,0,3.6-1.61,3.6-3.6V43.82c0-1.99-1.61-3.6-3.6-3.6H67.14C65.15,40.22,63.54,41.83,63.54,43.82"></path>
                            <path d="M55.39,36.46H44.61c-1.99,0-3.6-1.61-3.6-3.6V4.1c0-1.99,1.61-3.6,3.6-3.6h10.77c1.99,0,3.6,1.61,3.6,3.6v28.76C58.99,34.85,57.38,36.46,55.39,36.46"></path>
                    
                        </svg>

                        <div className="shape-inner">
                            <h1>Shape</h1>
                            
                            <h4>Business made simple</h4>
                            <p>A good action plan and execution help companies face challenges fairly easy, that’s exactly what we do.</p>
                            <Button textBtn="Let's Talk"/>
                        </div>
                        
                    </div>

                    {/* <div className="vdo-btn">
                        <button>Know More</button>
                    </div> */}
                </div>
            </div>

           
        </div>
    )
}


export default TextScroller
