import React from 'react';
import Button from '../Button/Button';
import './Experts.scss'

function Experts() {
  return (
      <div className='experts'>
          <div className="custom-container">
              <div className="experts-wrapper">
                    <p className='ex-caption'>Contact</p>
                    <h1 className='ex-title'>Talk with one of our experts</h1>
                    <p className='ex-sent'>Our experts understand, guide, and concretize your projects with custom solutions!</p>
                    <Button textBtn="Let's Talk"/>
              </div>
          </div>
      </div>
  );
}

export default Experts;
