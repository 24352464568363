import React from 'react';
import Button from '../Button/Button';
import './SolutionsHero.scss'

function SolutionsHero(props) {
  return (
      <div className='solutions-hero'>
          <div className="solutions-wrapper">
              <div className="solutions-hero__title">
                  <h1>
                      {props.line1}<br/>{props.line2}<br/>{props.line3}
                  </h1>
                  <Button textBtn="Let's Talk"/>
              </div>

              <div className="solutions-hero__image">
                  
              </div>
          </div>
      </div>
  )
}

export default SolutionsHero;
