import React from 'react'
import Button from '../../Button/Button'
import './Card.scss'

function Card(props) {
    return (
        <div id="card">
            <article className='card'>
                <a className='card-link'>
                    <div className="inner-wrapper">
                        <span className="image-wrapper">
                            <img src={props.url} alt="" />
                        </span>

                        <span className='card-btn'>
                            {/* <Button textBtn="View Industry"/> */}
                        </span>
                    </div>

                    <div className="card-title">
                        <h3>{props.heading}</h3>
                    </div>

                    <div className="card-content">
                        <p>
                            {props.content}
                        </p>
                    </div>
                </a>
            </article>
        </div>
    )
}

export default Card
