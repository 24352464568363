import React from 'react';
import Dragger from '../Components/Dragger/Dragger';
import DraggerClients from '../Components/DraggerClients/DraggerClients';
import Footer from '../Components/Footer/Footer';
import Navbar from '../Components/Navbar/Navbar';
import ParaContent from '../Components/ParaContent/ParaContent';
import Partners from '../Components/Partners/Partners';
import TextScroller from '../Components/TextScroller/TextScroller';
import Accordion from '../Components/Accordion/Accordion';
import AboutHero from '../Components/AboutHero/AboutHero';
import ScrollToTop from '../Components/ScrollToTop'
import { AboutText } from '../Components/Data';

function About() {  
  return (
  <div id="about">
      
      <Navbar/>
      <AboutHero/>
      <ParaContent paraContentTitle={AboutText.paraTitle1} 
      paraContentLine1={AboutText.paraLine1}
      paraContentLine2={AboutText.paraLine2}
      paraContentLine3={AboutText.paraLine3}
      paraContentLine4={AboutText.paraLine4}
      />
      <DraggerClients/>
      <TextScroller/>
      <Partners/>
      <Dragger/>
      <Accordion/>
      <Footer/>
  </div>
  );
}

export default About;
