import React from 'react';
import './RandomImg.scss'

function RandomImg(props) {
  return (
      <div className='random-img'>
          <div className="custom-container">
            <div className="img-wrapper">
                <img src={props.randomLink} alt="" />
            </div>
          </div>
      </div>
  );
}

export default RandomImg;
