import React from 'react'
import './ClientCard.scss'

function ClientCard(props) {
    return (
        <div id="client-card">
            <article className='client-card'>
               <div className="company-logo">
                   <img src={props.image} alt="" />
               </div>
               
               <div className="company-title">
                   <h5>
                       {props.name}
                   </h5>
               </div>
            </article>
        </div>
    )
}


export default ClientCard
