import React from 'react'

function Industry() {
    return (
        <div id="industry">
            <div className="industry-wrapper">
                
            </div>
        </div>
    )
}

export default Industry
