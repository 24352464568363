import React from 'react';
import ContactForm from '../Components/ContactForm/ContactForm';
import Footer from '../Components/Footer/Footer';
import Navbar from '../Components/Navbar/Navbar';
import ScrollToTop from '../Components/ScrollToTop'

function Contact() {
  return (
      <div>
          <Navbar/>
          <ContactForm/>
          <Footer/>
      </div>
  );
}

export default Contact;
